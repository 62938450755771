import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import _ from "lodash";
import { filterUsersApi } from "../../api/user";
import Datatable, { DatatableElement } from "../../component/datatable";
import Meta from "../../interface/meta";
import { IUser } from "../../interface/user";
import { setAppLoading } from "../../redux/app.reducer";
import { useAppDispatch } from "../../redux/hooks";
import { createRoot } from "react-dom/client";
import Pagination from "../../component/pagination";
import Breadcrumb from "../../component/breadcrumb";
import ModalViewImage from "../../component/modal-view-image";
import { getSrcImage } from "../../utils/utils";
import { toBath } from "../../utils/moneyFormatter";

const UserList = () => {
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const [openSnackbar] = useSnackbar({ position: "bottom-left" });
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<IUser[]>([]);
  const [showImage, setShowImage] = useState({ show: false, src: "" });
  const name = useRef<HTMLInputElement>(null);
  const status = useRef<HTMLSelectElement>(null);
  const isDealer = useRef<HTMLSelectElement>(null);
  const table = useRef<DatatableElement>(null);
  const [meta, setMeta] = useState<Meta>({
    page: 0,
    limit: 10,
    totalCount: 0,
    hasMore: true,
  });

  const filterUsers = async (page: number, limit: number) => {
    try {
      setLoading(true);
      const { data } = await filterUsersApi({
        role: "user",
        name: name.current?.value,
        status: status.current?.value ?? "0,1",
        isDealer: isDealer.current?.value ?? "",
        page,
        limit,
      });

      setUsers(data.results ?? []);
      setMeta({
        page: page,
        limit: limit,
        totalCount: data.meta.totalCount,
        hasMore: data.meta.hasMore,
      });
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  useEffect(() => {
    filterUsers(1, 10);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setAppLoading(loading));
    // eslint-disable-next-line
  }, [loading]);

  let debounce: any = undefined;
  return (
    <>
      <div className="d-flex align-items-center justify-content-between flex-wrap">
        <Breadcrumb
          title={"สมาชิก"}
          children={[{ title: "หน้าแรก", link: "/" }, { title: "สมาชิก" }]}
        />
        {/* <button className="btn btn-primary">
          <i className="fa fa-plus mr-2" />เพิ่มสมาชิก
        </button> */}
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <h3 className="col-lg-4 mt-2 mb-2">
                  ทั้งหมด {meta.totalCount.toLocaleString("en-US")} รายการ
                </h3>
                <div className="col-lg p-0">
                  <div className="form-row mb-2">
                    <div className="col-lg-4 ml-auto mb-2">
                      <input
                        type="text"
                        className="form-control global_filter"
                        ref={name}
                        placeholder="ค้นหาชื่อสมาชิก"
                        onChange={() => {
                          if (!!debounce) {
                            debounce.cancel();
                          }
                          debounce = _.debounce(() => {
                            filterUsers(1, meta.limit);
                          }, 500);
                          debounce();
                        }}
                      />
                    </div>

                    {/* <div className='col-lg-2 mb-2'>
                      <select className="form-control" ref={isDealer} onChange={() => {
                        filterUsers(1, meta.limit)
                      }}>
                        <option value="">ทั้งหมด</option>
                        <option value="true">ตัวแทน</option>
                        <option value="false">สมาชิก</option>
                      </select>
                    </div> */}

                    <div className="col-lg-2  mb-2">
                      <select
                        className="form-control"
                        ref={status}
                        onChange={() => {
                          filterUsers(1, meta.limit);
                        }}
                      >
                        <option value="0,1">ทั้งหมด</option>
                        <option value="1">ใช้งาน</option>
                        <option value="0">ระงับ</option>
                      </select>
                    </div>

                    <div className="col-auto  mb-2">
                      <button
                        onClick={() => {}}
                        type="button"
                        className="btn btn-outline-primary"
                      >
                        <i className="fa fa-download mr-2" />
                        Export
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <Datatable
                ref={table}
                data={users}
                columns={[
                  {
                    title: "รูป",
                    width: "80px",
                    data: null,
                    createdCell(cell, cellData, rowData: IUser) {
                      const cb = (src) => {
                        setShowImage({
                          src: src,
                          show: true,
                        });
                      };
                      createRoot(cell).render(
                        <div
                          className="cursor-pointer img-thumbmail"
                          onClick={() => {
                            cb(getSrcImage(rowData.picture));
                          }}
                        >
                          <img
                            className="lazy img-responsive"
                            alt=""
                            src={getSrcImage(rowData.picture)}
                          />
                        </div>
                      );
                    },
                  },
                  {
                    title: "รหัส",
                    width: 100,
                    data: "userNo",
                  },
                  {
                    title: "ชื่อ-นามสกุล",
                    width: 200,
                    render: (_1, _2, row: IUser) => {
                      return `${row.firstName} ${row.lastName}`;
                    },
                  },
                  // {
                  //   title: "รายได้",
                  //   width: 120,
                  //   data: null,
                  //   createdCell(cell, cellData, rowData: IUser) {
                  //     createRoot(cell).render(
                  //       <div
                  //         onClick={() => {
                  //           navigate(
                  //             `${process.env.PUBLIC_URL}/users/income/${rowData.id}`
                  //           );
                  //         }}
                  //         className="btn btn-outline-primary mr-1 mb-1 pt-2"
                  //       >
                  //         {toBath(rowData.credit)}
                  //       </div>
                  //     );
                  //   },
                  // },
                  {
                    title: "การใช้งาน",
                    width: 120,
                    data: null,
                    createdCell(cell, cellData, rowData: IUser) {
                      createRoot(cell).render(
                        <div
                          onClick={() => {
                            navigate(
                              `${process.env.PUBLIC_URL}/users/history/${rowData.id}`
                            );
                          }}
                          className="btn btn-outline-primary mr-1 mb-1 pt-2"
                        >
                          <span className="fa fa-eye"></span>
                        </div>
                      );
                    },
                  },
                  {
                    title: "บัญชี",
                    width: 100,
                    data: null,
                    createdCell(cell, cellData, rowData: IUser) {
                      createRoot(cell).render(
                        <div
                          onClick={() => {
                            navigate(
                              `${process.env.PUBLIC_URL}/users/book-bank/${rowData.id}`
                            );
                          }}
                          className="btn btn-outline-primary mr-1 mb-1 pt-2"
                        >
                          <span className="fa fa-eye"></span>
                        </div>
                      );
                    },
                  },
                  {
                    title: "ทีมงาน",
                    width: 100,
                    data: null,
                    createdCell(cell, cellData, rowData: IUser) {
                      createRoot(cell).render(
                        <div
                          onClick={() => {
                            navigate(
                              `${process.env.PUBLIC_URL}/users/team/${rowData.id}`
                            );
                          }}
                          className="btn btn-outline-primary mr-1 mb-1 pt-2"
                        >
                          <span className="fa fa-eye"></span>
                        </div>
                      );
                    },
                  },
                  // {
                  //   title: "ประเภท",
                  //   width: 100,
                  //   data: "isDealer",
                  //   render(data: boolean, type, row, meta) {
                  //     if (data)
                  //       return "<span class='text-success'>ตัวแทน</span>";
                  //     return "<span class='text-info'>สมาชิก</span>";
                  //   },
                  // },
                  {
                    title: "สถานะ",
                    width: 100,
                    data: "status",
                    render: (data: number) => {
                      switch (data) {
                        case 0:
                          return "<span class='badge badge-warning'>ระงับ</span>";
                        case 1:
                          return "<span class='badge badge-success'>ใช้งาน</span>";
                        default:
                          return "";
                      }
                    },
                  },
                ]}
                actions={[
                  {
                    button: {
                      callback(data: IUser) {
                        navigate(
                          `${process.env.PUBLIC_URL}/users/edit/${data.id}`
                        );
                      },
                      code: (
                        <button className="btn btn-outline-primary btn-sm mb-1">
                          แก้ไข
                        </button>
                      ),
                    },
                  },
                ]}
              />

              <Pagination
                active={meta.page}
                limit={meta.limit}
                onPageChange={(page) => {
                  filterUsers(page, meta.limit);
                }}
                pageRangeDisplayed={5}
                total={meta.totalCount}
              />
            </div>
          </div>
        </div>
      </div>
      <ModalViewImage
        open={showImage.show}
        src={showImage.src}
        toggle={() => {
          setShowImage((prev) => ({
            ...prev,
            show: false,
          }));
        }}
      />
    </>
  );
};

export default UserList;
