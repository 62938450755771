import { ReactNode, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import Meta from "../../interface/meta";
import { setAppLoading } from "../../redux/app.reducer";
import { useAppDispatch } from "../../redux/hooks";
import Breadcrumb from "../../component/breadcrumb";
import { confirmOrderApi, filterOrdersApi } from "../../api/order";
import { IFilterOrder } from "../../interface/order";
import { getProfileUserByApi } from "../../api/user";
import { IUser } from "../../interface/user";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import Pagination from "../../component/pagination";
import ModalViewImage from "../../component/modal-view-image";
import { getSrcImage } from "../../utils/utils";
import FileSaver from "file-saver";

const UserHistory = () => {
  let { id } = useParams();
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const [openSnackbar] = useSnackbar({ position: "bottom-left" });
  const [loading, setLoading] = useState(false);
  const [showImage, setShowImage] = useState({ show: false, src: "" });
  const [orders, setOrders] = useState<IFilterOrder[]>([]);
  const [user, setUser] = useState<IUser>();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [meta, setMeta] = useState<Meta>({
    page: 0,
    limit: 10,
    totalCount: 0,
    hasMore: true,
  });
  const [filter, setFilter] = useState({
    orderNo: "",
    status: "",
    begin: 0,
    end: 0,
  });

  const getProfileUserById = async () => {
    try {
      const { data } = await getProfileUserByApi(id!);
      setUser(data);
    } catch (e: any) {
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  const filterOrders = async (page: number, limit: number) => {
    try {
      setLoading(true);
      const { data } = await filterOrdersApi({
        userId: id,
        begin: filter.begin !== 0 ? moment.unix(filter.begin).utc().unix() : 0,
        end: filter.end !== 0 ? moment.unix(filter.end).utc().unix() : 0,
        orderNo: !filter.orderNo ? undefined : filter.orderNo,
        status: !filter.status ? undefined : filter.status,
        page,
        limit,
      });

      setOrders(data.results ?? []);

      setMeta({
        page: page,
        limit: limit,
        totalCount: data.meta.totalCount,
        hasMore: data.meta.hasMore,
      });
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
      navigate(`${process.env.PUBLIC_URL}/users`);
    }
  };

  const confirmOrder = async (id: string, status: 0 | 1) => {
    try {
      setLoading(true);
      await confirmOrderApi({ id, status });
      await Promise.all([filterOrders(meta.page, meta.limit)]);
      openSnackbar("Success");
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  const exportOrderCsv = async (id: string) => {
    try {
      setLoading(true);
      const response = await filterOrdersApi({
        userId: id,
        begin: filter.begin !== 0 ? moment.unix(filter.begin).utc().unix() : 0,
        end: filter.end !== 0 ? moment.unix(filter.end).utc().unix() : 0,
        orderNo: !filter.orderNo ? undefined : filter.orderNo,
        status: !filter.status ? undefined : filter.status,
        type: "csv",
      });

      let BOM = "\uFEFF";
      let csvData = (BOM + response.data) as any;
      let blob = new Blob([csvData], {
        type: "text/csv;charset=utf-8",
      });

      FileSaver.saveAs(blob, `order-history.csv`);

      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  useEffect(() => {
    getProfileUserById();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    filterOrders(1, 10);
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    dispatch(setAppLoading(loading));
    // eslint-disable-next-line
  }, [loading]);

  return (
    <>
      <Breadcrumb
        title={"สมาชิก"}
        children={[
          { title: "หน้าแรก", link: "/" },
          { title: "สมาชิก", link: "/users" },
          { title: `${user?.firstName ?? ""} ${user?.lastName ?? ""}` },
          { title: "ประวัติการใช้งาน" },
        ]}
      />

      <div className="row">
        <div className="col-12 m-0">
          <div className="row">
            <div className="col">
              <ul
                className="nav nav-pills nav-fill"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item mb-2">
                  {/* <span className="nav-link active">ประวัติส่วนตัว</span> */}
                  <Link className="nav-link" to={`/users/edit/${id}`}>
                    ประวัติส่วนตัว
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link className="nav-link" to={`/users/income/${id}`}>
                    รายได้
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <span className="nav-link active">ประวิติการใช้งาน</span>
                  {/* <Link className="nav-link" to={`/users/history/${id}`}>ประวิติการใช้งาน</Link> */}
                </li>
                <li className="nav-item mb-2">
                  {/* <span className="nav-link active">บัญชีรับรางวัล</span> */}
                  <Link className="nav-link" to={`/users/book-bank/${id}`}>
                    บัญชีรับรางวัล
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link className="nav-link" to={`/users/team/${id}`}>
                    ทีมงาน
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 mb-3">
          <div className="card">
            <div className="card-body">
              <div className="row mb-2">
                <div className="col-lg mt-2">
                  <span>
                    ทั้งหมด {meta.totalCount.toLocaleString("en-US")} รายการ
                  </span>
                </div>
                <div className="col-md mb-1">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="หมายเลขคำสั่งซื้อ"
                    value={filter.orderNo}
                    onChange={(e) => {
                      setFilter((prev) => ({
                        ...prev,
                        orderNo: e.target.value,
                      }));
                    }}
                  />
                </div>
                <div className="col-md mb-1">
                  <ReactDatePicker
                    selected={startDate}
                    onChange={(dates: [Date | null, Date | null]) => {
                      const [start, end] = dates;
                      setStartDate(start);
                      setEndDate(end);
                      if ((!!start && !!end) || (!start && !end)) {
                        setFilter((prev) => ({
                          ...prev,
                          begin: (start?.valueOf() ?? 0) / 1000,
                          end: (end?.valueOf() ?? 0) / 1000,
                        }));
                      }
                    }}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    dateFormat={"dd/MM/yyyy"}
                    isClearable={true}
                    placeholderText="วันที่ จาก - ถึง"
                    customInput={<input className="form-control" />}
                  />
                </div>
                <div className="col-lg-2 mb-1">
                  <div className="form-group">
                    <select
                      className="form-control w-100"
                      value={filter.status}
                      onChange={(e) => {
                        setFilter((prev) => ({
                          ...prev,
                          status: e.target.value,
                        }));
                      }}
                    >
                      <option value="">ทั้งหมด</option>
                      <option value="0">ยกเลิก</option>
                      <option value="1">สำเร็จ</option>
                      <option value="2">จอง</option>
                      <option value="3">รอชำระเงิน</option>
                      <option value="5">รอยืนยัน</option>
                    </select>
                  </div>
                </div>

                <div className="col-auto mr-2">
                  <button
                    className="btn btn-outline-primary"
                    onClick={() => {
                      exportOrderCsv(id!);
                    }}
                  >
                    Export
                  </button>
                </div>
              </div>

              <div className="table-responsive">
                <table className="table text-wrap table_new" cellSpacing="0">
                  <thead>
                    <tr>
                      <th>หมายเลขคำสั่งซื้อ</th>
                      <th>วันเวลา</th>
                      <th>จำนวน(ใบ)</th>
                      <th>ราคา(บาท)</th>
                      <th style={{ width: 150 }}>ไฟล์แนบ</th>
                      <th>การชำระเงิน</th>
                      <th>สถานะ</th>
                      <th className="text-right">ดำเนินการ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders.map((order) => {
                      let status: ReactNode;
                      switch (order.status) {
                        case 0:
                          status = (
                            <span className="badge badge-small badge-danger">
                              ยกเลิก/หมดเวลา
                            </span>
                          );
                          break;
                        case 1:
                          status = (
                            <span className="badge badge-small badge-success">
                              สำเร็จ
                            </span>
                          );
                          break;
                        case 2:
                          status = (
                            <span className="badge badge-primary badge-small">
                              จอง
                            </span>
                          );
                          break;
                        case 3:
                          status = (
                            <span className="badge badge-small badge-info">
                              รอชำระเงิน
                            </span>
                          );
                          break;
                        case 4:
                          status = (
                            <span className="badge badge-small badge-warning">
                              รอยืนยัน
                            </span>
                          );
                          break;
                        case 5:
                          status = (
                            <span className="badge badge-small badge-warning">
                              รอยืนยัน
                            </span>
                          );
                          break;
                        default:
                          status = (
                            <span className="badge badge-small badge-light">
                              -
                            </span>
                          );
                      }

                      return (
                        <tr key={order.id}>
                          <td>
                            <div
                              className="hover-underline cursor-pointer"
                              onClick={() =>
                                navigate(
                                  `${process.env.PUBLIC_URL}/orders/${order.id}`
                                )
                              }
                            >
                              {order.orderNo}
                            </div>
                          </td>
                          <td>
                            {moment(order.createdAt).format(
                              "DD/MM/yyyy HH:mm:ss"
                            )}
                          </td>
                          <td>{order.totalLottery}</td>
                          <td>{order.totalAmount}</td>
                          <td>
                            {order.slip.length > 0 && (
                              <div
                                className="cursor-pointer img-thumbmail"
                                onClick={() => {
                                  setShowImage({
                                    show: true,
                                    src: getSrcImage(order.slip),
                                  });
                                }}
                              >
                                <img
                                  className="lazy img-responsive"
                                  alt=""
                                  src={getSrcImage(order.slip, "?tr=w-50")}
                                />
                              </div>
                            )}
                          </td>
                          <td>{order.paymentMethod}</td>
                          <td>{status}</td>
                          <td className="text-right">
                            <div className="dropdown">
                              <button
                                className="btn btn-outline-primary dropdown-toggle"
                                type="button"
                                id="dropdownMenuIconButton2"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                {" "}
                                เพิ่มเติม
                              </button>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuIconButton2"
                              >
                                {[2, 3, 5].includes(order.status) && (
                                  <>
                                    {order.status === 5 && (
                                      <div
                                        className="dropdown-item cursor-pointer"
                                        onClick={() => {
                                          confirmOrder(order.id, 1);
                                        }}
                                      >
                                        <span className="fa fa-check mr-2 text-success"></span>
                                        อนุมัติ/ยืนยัน
                                      </div>
                                    )}
                                    <div
                                      className="dropdown-item cursor-pointer"
                                      onClick={() => {
                                        confirmOrder(order.id, 0);
                                      }}
                                    >
                                      <span className="fa fa-times mr-2 text-danger"></span>
                                      ยกเลิก
                                    </div>
                                    <div className="dropdown-divider"></div>
                                  </>
                                )}
                                <div
                                  className="dropdown-item cursor-pointer"
                                  onClick={() => {
                                    navigate(
                                      `${process.env.PUBLIC_URL}/orders/${order.id}`
                                    );
                                  }}
                                >
                                  <span className="fa fa-edit mr-2"></span>
                                  รายละเอียด
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <Pagination
                active={meta.page}
                limit={meta.limit}
                onPageChange={(page) => {
                  filterOrders(page, meta.limit);
                }}
                pageRangeDisplayed={5}
                total={meta.totalCount}
              />
            </div>
          </div>
        </div>
      </div>

      <ModalViewImage
        open={showImage.show}
        src={showImage.src}
        toggle={() => {
          setShowImage((prev) => ({
            ...prev,
            show: false,
          }));
        }}
      />
    </>
  );
};

export default UserHistory;
