import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken as FGetToken,
  MessagePayload,
  onMessage,
} from "firebase/messaging";
import debouce from "lodash/debounce";

const firebaseConfig = {
  apiKey: "AIzaSyARD_3Wb6wYkXIYHSMJO8_USNucz-7m0qU",
  appId: "1:46243798040:web:a3ecf79af70cca4ca8b76d",
  messagingSenderId: "46243798040",
  projectId: "kodwing-lottery",
  authDomain: "kodwing-lottery.firebaseapp.com",
  storageBucket: "kodwing-lottery.appspot.com",
  measurementId: "G-HDSJ1W2RKC",
};

const msgKey =
  "BIbRIJmBHVKr24gxDYmHPemY6Q71IfSak_5EDg7Ss4dWVNeAJD5hnVBpiK1Re35kaLFc-sxwe4orN3jBZpbZnCU";

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getToken = async (): Promise<string | null> => {
  try {
    let currentToken = await FGetToken(messaging, { vapidKey: msgKey });
    if (currentToken) {
      console.log("current token for client: ", currentToken);
      return currentToken;
    } else {
      console.log(
        "No registration token available. Request permission to generate one."
      );
      return null;
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
    throw error;
  }
};

export const onMessageListener = (
  cb: (data: MessagePayload) => void
): Promise<MessagePayload> =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      cb(payload);
      debouncedPlaySound();
      resolve(payload);
    });
  });

function playSound() {
  var audio = new Audio("/audio/new-order.mp3");
  audio.play();
}

// Usage:
// This will create a debounced version of the playSound function
// that, when called repeatedly, will only actually invoke playSound
// at most once every 1 second.
var debouncedPlaySound = debouce(() => {
  playSound();
}, 1000);
